import { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ErrorPage from "../../display/ErrorPage";
import CardListDisplay from "../../display/CardListDisplay";


function ReactionDetails(props) {
    const {
        reactionProps,
        // cards
    } = props
    const {reaction_id} = useParams()

    const [reaction, setReaction ] = useState({
        name: "",
        rules: "",
        reaction_number: "",
        support: [],
        anti_support: [],
    });

    const [noReaction, setNoReaction] = useState(false)

    const [support_list, setSupportList] = useState([]);
    const [anti_support_list, setAntiSupportList] = useState([]);

    const [members, setMembers] = useState([]);

    const [showPool, setShowPool] = useState(true);
    const [showSupport, setShowSupport] = useState(true);
    const [showAntiSupport, setShowAntiSupport] = useState(true);

    const getReaction = async() =>{
        const reaction_data = reactionProps.find(reactionProp => reactionProp.id === reaction_id);
        if (reaction_data) {
            setReaction(reaction_data);

            const cardsResponse = await fetch("https://pm-deck-react-only.onrender.com/cards")
            const cardsData = await cardsResponse.json()
            const sortedCards = [...cardsData].sort((a,b) => a.name.localeCompare(b.name));
            const reactionMembersList = sortedCards.filter(card => card.reactions.includes(reaction_data.reaction_number))
            setMembers(reactionMembersList)

            const support_card_list = reaction_data.support.map(supportItem =>
            cardsData.find(card => card.card_number === supportItem))
            const anti_support_card_list = reaction_data.anti_support.map(antiSupportItem =>
            cardsData.find(card => card.card_number === antiSupportItem))
            setSupportList(support_card_list)
            setAntiSupportList(anti_support_card_list)
        } else {
            setNoReaction(true)
        }
    };

    useEffect(() => {
        window.scroll(0, 0);
        getReaction();

    // eslint-disable-next-line
    },[]);

    useEffect(() => {;
        document.title = `${reaction.name} - PM CardBase`
        console.log(reaction.rules)
        console.log(preprocessCompText(replaceCount(reaction)))
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[reaction]);

    const handleShowPool = (event) => {
        setShowPool(!showPool);
    };

    const handleShowSupport = (event) => {
        setShowSupport(!showSupport);
    };

    const handleShowAntiSupport = (event) => {
        setShowAntiSupport(!showAntiSupport);
    };

    const preprocessCompText = (text) => {
        return text.split("\n");
    };

    const preprocessText = (text) => {
        return text.split("//").join("\n");
    };

    const replaceCount = (reaction) => {
        return reaction.rules.replace("{count}", `X, where "X" is the count of ${reaction.name}`)
    }

    return (
        <>
            { !noReaction?
                <div className="white-space">
                    <h1 className="margin-top-40">{reaction.name}</h1>
                    <div>
                        {preprocessCompText(replaceCount(reaction)).map(line => {
                            return(<h2>{line}</h2>)})}
                    </div>
                    <div className={showPool ? "rarities" : "no-rarities"} style={{marginTop: "20px"}}>

                        <div style={{display: "flex", alignItems: "center"}}>
                            <h2
                                className="left"
                                style={{margin: "1% 0px 1% 20px", fontWeight: "700"}}
                            >Members</h2>
                            <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                            {members.length > 0 ?
                                <h5
                                    className="left db-pool-count"
                                >{members.length}</h5>:
                                null}
                            { showPool ?
                                <h5 className="left db-pool-count"
                                    onClick={() => handleShowPool()}>
                                        &nbsp;[Hide]
                                </h5> :
                                <h5 className="left db-pool-count"
                                    onClick={() => handleShowPool()}>
                                    &nbsp;[Show]
                                </h5>}
                        </div>
                        <CardListDisplay
                            showPool={showPool}
                            members={members}
                        />

                    </div>

                    <div className={support_list.length > 0? "support":"hidden2"}>
                        <div>
                            <div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                <h2
                                    className="left"
                                    style={{margin: "1% 0%", fontWeight: "700"}}
                                >Support</h2>
                                <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                {support_list.length > 0 ?
                                <h5
                                    className="left"
                                    style={{margin: "1% 0%", fontWeight: "700"}}
                                >{support_list.length}</h5>:
                                null}
                                { showSupport ?
                                    <h5 className={support_list.length > 0 ? "left db-main-count" : "hidden2"}
                                        onClick={() => handleShowSupport()}>
                                            &nbsp;[Hide]
                                    </h5> :
                                    <h5 className={support_list.length > 0 ? "left db-main-count" : "hidden2"}
                                        onClick={() => handleShowSupport()}>
                                        &nbsp;[Show]
                                    </h5>}
                            </div>

                            {support_list.length > 0 ?
                            <CardListDisplay
                                showPool={showSupport}
                                members={support_list}
                            />:
                            <h4 className="left no-cards">No cards added</h4>}
                        </div>
                    </div>

                    <div className={anti_support_list.length > 0? "anti_support":"hidden2"}>
                        <div>
                            <div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                <h2
                                    className="left"
                                    style={{margin: "1% 0%", fontWeight: "700"}}
                                >Anti-Support</h2>
                                <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                {anti_support_list.length > 0 ?
                                <h5
                                    className="left"
                                    style={{margin: "1% 0%", fontWeight: "700"}}
                                >{anti_support_list.length}</h5>:
                                null}
                                { showAntiSupport ?
                                    <h5 className={anti_support_list.length > 0 ? "left db-main-count" : "hidden2"}
                                        onClick={handleShowAntiSupport}
                                    >
                                        &nbsp;[Hide]
                                    </h5> :
                                    <h5 className={anti_support_list.length > 0 ? "left db-main-count" : "hidden2"}
                                        onClick={handleShowAntiSupport}
                                    >
                                        &nbsp;[Show]
                                    </h5>}
                            </div>
                            {anti_support_list.length > 0 ?
                            <CardListDisplay
                                showPool={showAntiSupport}
                                members={anti_support_list}
                            />:
                        <h4 className="left no-cards">No cards added</h4>}
                    </div>
                    </div>
                </div>:
                <ErrorPage path={"/reactions/"}/>
            }
        </>
    );
}

export default ReactionDetails;
