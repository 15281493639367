import { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ErrorPage from "../display/ErrorPage";
import { AuthContext } from "../context/AuthContext";
import CardListDisplay from "../display/CardListDisplay";


function SearchGroupDetailPage() {
    const {group_id} = useParams()
    const { account } = useContext(AuthContext)

    const [searchGroup, setSearchGroup] = useState({
        name: "",
        groupCategory: "",
        members: []
    });
    const [noSearchGroup, setNoSearchGroup ] = useState(false)
    const [members, setMembers] = useState([]);
    const [showPool, setShowPool] = useState(true);

    const getSearchGroup = async() =>{
        // const groupResponse = await fetch(`http://localhost:4000/search_groups/${group_id}`)
        const groupResponse = await fetch(`https://pm-deck-react-only.onrender.com/search_groups/${group_id}`)
        if (groupResponse.ok) {
            const groupData = await groupResponse.json()
            setSearchGroup(groupData)

            const cardsResponse = await fetch("https://pm-deck-react-only.onrender.com/cards")
            // const cardsResponse = await fetch("http://localhost:4000/cards/")
            const cardsData = await cardsResponse.json()

            const sortedCards = [...cardsData].sort((a,b) => a.name.localeCompare(b.name));
            const membersList = sortedCards.filter(card => groupData.members.includes(card.card_number))
            console.log(membersList)
            setMembers(membersList)
        } else {
            setNoSearchGroup(true)
        }
    };

    const nameCategory = () => {
        const names = {
            damage: "Damage: ",
            cardGain: "Card Gain: ",
            playerBuff: "Player/Card Buff: ",
            playerDebuff: "Player/Card Debuff: ",
            target: "Target: ",
            cardChange: "Card Change: ",
            deckChange: "Deck Change: ",
            boardChange: "Play-Area Change: ",
            playerAction: "Player Action: ",
            activationTiming: "Activation Timing: ",
            resolution: "Resolution Effect: ",
            effectType: "Effect Type: ",
            cost: "Cost: ",
        }
        return names[searchGroup.groupCategory]
    }

    useEffect(() => {
        window.scroll(0, 0);
        getSearchGroup()
    // eslint-disable-next-line
    },[group_id]);

    useEffect(() => {
        document.title = `${searchGroup.name} - PM CardBase`
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[searchGroup]);

    const handleShowPool = (event) => {
        setShowPool(!showPool);
    };

    const preprocessText = (text) => {
        return text.split("//").join("\n");
    };

    return (
        <>
            { !noSearchGroup?
                <div className="white-space content flex-column-split">
                    <span>
                        <div className="flex-content">
                            <h1 className="margin-top-10 ellipsis">{nameCategory()} {searchGroup.name}</h1>
                            { account && account.roles.includes("admin")?
                                <NavLink className="nav-link" to={`/groups/${group_id}/edit`}>
                                    <h5>[Edit]</h5>
                                </NavLink>
                            :null}
                        </div>
                        <div className={showPool ? "rarities" : "no-rarities"} style={{marginTop: "20px"}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <h2
                                    className="left"
                                    style={{margin: "1% 0px 1% 20px", fontWeight: "700"}}
                                >Members</h2>
                                <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                {members.length > 0 ?
                                    <h5
                                        className="left db-pool-count"
                                    >{members.length}</h5>:
                                    null}
                                { showPool ?
                                    <h5 className="left db-pool-count"
                                        onClick={() => handleShowPool()}>
                                            &nbsp;[Hide]
                                    </h5> :
                                    <h5 className="left db-pool-count"
                                        onClick={() => handleShowPool()}>
                                        &nbsp;[Show]
                                    </h5>}
                            </div>
                            <CardListDisplay
                                showPool={showPool}
                                members={members}
                            />
                        </div>
                    </span>
                    <NavLink className="nav-link no-pad" to={"/groups"}>
                        <button
                            style={{ width: "100%", marginTop: "20px"}}>
                            Back to Search Groups
                        </button>
                    </NavLink>
                </div>:
                <ErrorPage path={"/groups/"}/>
            }
        </>
    );
}

export default SearchGroupDetailPage;
