import { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ErrorPage from "../display/ErrorPage";
import { AuthContext } from "../context/AuthContext";
import CardListDisplay from "../display/CardListDisplay";


function RelatedCardPage() {
    const { full_card_number } = useParams()
    const { account } = useContext(AuthContext)

    const [cards, setCards] = useState([]);
    const [card, setCard] = useState("")
    const [noCards, setNoCards] = useState(false);
    const [showPool, setShowPool] = useState(true);

    const getRelatedCards = async() =>{
        // const cardsResponse = await fetch(`http://localhost:4000/cards/related/${full_card_number}`)
        const cardsResponse = await fetch(`https://pm-deck-react-only.onrender.com/cards/related/${full_card_number}`)
        if (cardsResponse.ok) {
            const cardsData = await cardsResponse.json()
            const sortedCards = [...cardsData]
                .filter(card => card.card_number !== parseInt(getCardNumber()[1], 10))
                .sort((a,b) => a.name.localeCompare(b.name));
            const cardData = cardsData.find(card => card.card_number === parseInt(getCardNumber()[1], 10))
            setCards(sortedCards)
            setCard(cardData)
        } else {
            setNoCards(true)
        }
    };

    useEffect(() => {
        window.scroll(0, 0);
        getRelatedCards()
    // eslint-disable-next-line
    },[full_card_number]);

    useEffect(() => {
        document.title = `Related Cards: ${card? card.name: ""}  - PM CardBase`
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[card]);

    const handleShowPool = (event) => {
        setShowPool(!showPool);
    };

    const getCardNumber = () => {
        return [
            full_card_number.slice(0,3).toUpperCase(),
            full_card_number.slice(3)
        ]
    }

    const preprocessText = (text) => {
        return text.split("//").join("\n");
    };

    return (
        <>
            { !noCards?
                <div className="white-space">
                    <div className="flex-content">
                        <h1 className="margin-top-10 ellipsis">
                            Related Cards: {card.name}
                        </h1>
                    </div>
                    <div className={showPool ? "rarities" : "no-rarities"} style={{marginTop: "20px"}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <h2
                                className="left"
                                style={{margin: "1% 0px 1% 20px", fontWeight: "700"}}
                            >Members</h2>
                            <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                            {cards.length > 0 ?
                                <h5
                                    className="left db-pool-count"
                                >{cards.length}</h5>:
                                null}
                            { showPool ?
                                <h5 className="left db-pool-count"
                                    onClick={() => handleShowPool()}>
                                        &nbsp;[Hide]
                                </h5> :
                                <h5 className="left db-pool-count"
                                    onClick={() => handleShowPool()}>
                                    &nbsp;[Show]
                                </h5>}
                        </div>
                        <CardListDisplay
                            showPool={showPool}
                            members={cards}
                        />
                    </div>
                    <NavLink className="nav-link no-pad" to={`/cards/${getCardNumber()[1]}`}>
                        <button
                            style={{ width: "100%", marginTop: "20px"}}>
                            Back to {card.name}
                        </button>
                    </NavLink>
                </div>:
                <ErrorPage path={"/cards"}/>
            }
        </>
    );
}

export default RelatedCardPage;
