import { useState, useEffect } from "react";
import DragToScroll from "../display/DragToScroll";
import PopOver from "../display/PopOver";


function TestHand(props) {
    const {
        main_list,
        pluck_list,
        testHand
    } = props

    const [shuffledDeck, setShuffledDeck] = useState([]);
    const [shuffledPluckDeck, setShuffledPluckDeck] = useState([]);
    const [mulliganList, setMulliganList] = useState([]);
    const [handSize, setHandSize] = useState(6)
    const [reserveSize, setReserveSize] = useState(1)

    const getShuffledDecks = () =>{
        if (testHand) {
            const shuffledDeck = [...main_list]
            let currentIndex = shuffledDeck.length, randomIndex;
            // While there remain elements to shuffle.
            while (currentIndex !== 0) {
                // Pick a remaining element.
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                // And swap it with the current element.
                [shuffledDeck[currentIndex], shuffledDeck[randomIndex]] = [
                shuffledDeck[randomIndex], shuffledDeck[currentIndex]];
            }
            setShuffledDeck(shuffledDeck);

            const shuffledPluckDeck = [...pluck_list]
            let currentPluckIndex = shuffledPluckDeck.length, randomPluckIndex;
            while (currentPluckIndex !== 0) {
                randomPluckIndex = Math.floor(Math.random() * currentPluckIndex)
                currentPluckIndex--;

                [shuffledPluckDeck[currentPluckIndex], shuffledPluckDeck[randomPluckIndex]] = [
                shuffledPluckDeck[randomPluckIndex], shuffledPluckDeck[currentPluckIndex]]
            }
            // const randomPluckIndex = Math.floor(Math.random() * pluck_list.length);
            setShuffledPluckDeck(shuffledPluckDeck);
        }
    }

    // const clearShuffledDecks = () =>{
    //     setShuffledDeck([]);
    //     setOwnership("");
    // }

    const handleMulliganChange = (index) => {
        console.log(mulliganList)
        if (mulliganList.includes(index)){
            setMulliganList(mulliganList.filter(i => i !== index))
        }else{
            setMulliganList([...mulliganList, index]);
        }
    }

    const mulligan = () => {
        const hand = shuffledDeck.slice(0, 6);  // The first 6 cards (the hand)
        const restOfDeck = shuffledDeck.slice(6);  // The rest of the deck after the hand

        // Cards to return (mulliganed cards)
        const cardsToReturn = mulliganList.map(index => hand[index]);

        // Remove the mulliganed cards from the hand and store the updated hand
        const updatedHand = hand.filter((_, index) => !mulliganList.includes(index));

        // Draw cards to replace the mulliganed cards
        const drawnCards = restOfDeck.slice(0, mulliganList.length);  // Draw as many cards as mulliganed
        const newHand = [...updatedHand, ...drawnCards];  // Updated hand after drawing

        // Add the mulliganed cards back to the rest of the deck
        const newDeckToShuffle = [...restOfDeck.slice(mulliganList.length), ...cardsToReturn];

        // Shuffle the remaining deck
        let currentIndex = newDeckToShuffle.length, randomIndex;
        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [newDeckToShuffle[currentIndex], newDeckToShuffle[randomIndex]] = [
                newDeckToShuffle[randomIndex], newDeckToShuffle[currentIndex]];
        }

        // Combine the updated hand with the shuffled deck and update the state
        const newShuffledDeck = [...newHand, ...newDeckToShuffle];

        console.log("Updated hand:", newHand);
        console.log("Shuffled deck:", newDeckToShuffle);
        setShuffledDeck(newShuffledDeck);  // Update the shuffled deck state
        setMulliganList([]);  // Clear the mulligan list for the next round
    };



    // const draw = (deckType) => {
    //     if (deckType === "main" && handSize < 8) {
    //         setHandSize(handSize + 1)
    //     } else if (deckType === "pluck" && reserveSize < 8) {
    //         setReserveSize(reserveSize + 1)
    //     }
    // }

    useEffect(() => {
        getShuffledDecks()
    },[testHand])

    const [hoveredCard, setHoveredCard] = useState(null)

    return (
        <>
            {testHand?
                <div className="hidden2 media-display margin-0">
                    <PopOver
                        content={hoveredCard}
                        setObject={setHoveredCard}
                    />
                    <DragToScroll style={{padding: "5px", margin: "-5px"}}>
                        <div className="dd-button-row flex" style={{padding: "5px", margin: "-5px"}}>
                            {shuffledDeck.length > 0 ?
                                <div className="maindeck2" style={{borderRadius: "5px 0 0 5px", margin: "0"}}>
                                    <div style={{marginLeft: "20px"}}>
                                        <div className="flex-items">
                                            <h2
                                                className="left"
                                                style={{margin: "10px 0", fontWeight: "700"}}
                                                >Test Hand
                                            </h2>
                                            <button
                                                style={{margin: "0 0 0 10px"}}
                                                onClick={mulligan}
                                            >
                                                Mulligan
                                            </button>
                                            {/* <button
                                                style={{margin: "0 0 0 10px"}}
                                                onClick={() => {draw("main")}}
                                            >
                                                Draw
                                            </button> */}
                                        </div>
                                        <div className="flex margin-bottom-10">
                                            {shuffledDeck.slice(0, handSize).map((card, index) => {
                                                return (
                                                    <img
                                                        onClick={() => handleMulliganChange(index)}
                                                        key={`${card.card_number}-${index}`}
                                                        style={{marginLeft: index === 0? "0": "10px", marginRight: index === handSize - 1? "20px": "10px", width: "110px"}}
                                                        className={mulliganList.includes(index) ? "selected builder-card margin-10" : "builder-card margin-10 trigger"}
                                                        src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                        onMouseEnter={() => setHoveredCard(card)}
                                                        onMouseLeave={() => setHoveredCard(null)}
                                                        alt={card.name}/>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>:
                                null
                            }
                            {shuffledPluckDeck.length > 0 ?
                                <div className="pluckdeck" style={{borderRadius: "0 5px 5px 0", margin: "0 4px"}}>
                                    <div className="flex-full">
                                        <h2
                                            // className="left"
                                            style={{margin: "10px 0", fontWeight: "700"}}
                                            >Reserve
                                        </h2>
                                    </div>
                                    <div className="flex margin-bottom-10">
                                        {shuffledPluckDeck.slice(0, reserveSize).map((card, index) => {
                                            return (
                                                <img
                                                    key={card.card_number + index}
                                                    style={{margin: "10px 20px", width: "110px"}}
                                                    className="builder-card margin-10"
                                                    src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                    onMouseEnter={() => setHoveredCard(card)}
                                                    onMouseLeave={() => setHoveredCard(null)}
                                                    alt={card.name}/>
                                            );
                                        })}
                                    </div>
                                </div>:
                                null
                            }
                        </div>
                    </DragToScroll>
                </div>: null
            }
        </>
    );
}


export default TestHand;
