import React, { useEffect, useState} from 'react';
import './PopOver.css';

const TextPopOver = ({
    content,
    setObject
}) => {

    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (event) => {
        setPosition({
            x: event.clientX,
            y: event.clientY - 20,
            placement: calcPosition(event)
        });
        if (
            !event.target.closest(".trigger")
        ) {
            setObject(null); // Clear the hover state if clicked outside
        }
    };

    const calcPosition = (event) => {
        const windowWidth = window.innerWidth
        const x = event.clientX
        const div = document.querySelector('.textPopOver'); // Replace '.myDiv' with your div's class or ID
        const divWidth = div? div.getBoundingClientRect().width: 0;
        if (x < windowWidth/3) {
            return divWidth/3
        }
        else if (x > 2 * windowWidth/3){
            return -divWidth/3
        }
        return 0
    }

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    const preprocessText = (text) => {
        return text.split("\n");
    };


    return (
        <>
        {content? (
            <div
                className="textPopOver noHover"
                style={{ top: position.y, left: position.x + position.placement }} // Offset by 10px for visibility
            >
                <p className="popOver1">{content.name}</p>
                {preprocessText(content.text)?.map((line, index) => {
                    return <p
                            className="popOver3"
                            style={{margin: "0"}}
                            key={index}
                        >{line}</p>
                })}
            </div>
        ) : null}
        </>
    );
};

export default TextPopOver;
