import React, { useEffect, useRef } from "react";

const AutoWidthText = ({ text, as: Tag = "p", style = {}, className = "" }) => {
    const elementRef = useRef(null);

    const adjustWidth = () => {
        if (elementRef.current) {
        const range = document.createRange();
        const textNode = elementRef.current.childNodes[0];

        if (textNode) {
            range.setStartBefore(textNode);
            range.setEndAfter(textNode);

            const clientRect = range.getBoundingClientRect();
            elementRef.current.style.width = `${clientRect.width}px`;
        }
        }
    };

    useEffect(() => {
        adjustWidth(); // Adjust width on initial render

        const handleResize = () => {
        // Reset the width temporarily to recalculate properly
        if (elementRef.current) {
            elementRef.current.style.width = "auto";
        }
        adjustWidth();
        };

        window.addEventListener("resize", handleResize); // Adjust on window resize
        return () => {
        window.removeEventListener("resize", handleResize); // Cleanup listener
        };
    }, [text]); // Rerun if the text changes

    return React.createElement(
        Tag,
        {
            ref: elementRef,
            style: { ...style, display: "inline-block" },
            className,
        },
        text
    );
};

export default AutoWidthText;
