import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SwipeableImage = ({
    card,
    prevLink,
    nextLink
}) => {
    const navigate = useNavigate()

    // const [swipeDirection, setSwipeDirection] = useState(null);
    const [startX, setStartX] = useState(0);

    const [dragPosition, setDragPosition] = useState({
        left: `${window.innerWidth/3}px`,
        right: 'auto',
    })

    const handleTouchStart = (event) => {
        // Store the starting touch X coordinate
        const clientX = event.touches ? event.touches[0].clientX : event.clientX;
        setStartX(clientX);
        setDragPosition({
            left: `${(event.clientX - window.innerWidth / 2) - 75}px`,
            right: 'auto',
        });
    };

    const handleTouchMove = (event) => {
        // Only update the drag position if the user is dragging
        const clientX = event.touches ? event.touches[0].clientX : event.clientX;
        setDragPosition({
            left: `${(clientX - window.innerWidth / 2) - 75}px`,
            right: 'auto',
        });
    };

    const handleTouchEnd = (event) => {
        // Get the ending touch X coordinate
        const endX = event.changedTouches ? event.changedTouches[0].clientX : event.clientX;
        const deltaX = endX - startX;

        // Determine the swipe direction based on the difference
        if (deltaX > 130 && prevLink) {
                navigate(prevLink); // Swipe right
            } else if (deltaX < -130 && nextLink) {
                navigate(nextLink); // Swipe left
            }

        setDragPosition({
            left: `${window.innerWidth / 3}px`,
            right: 'auto',
        })
    };

    return (
        <img
            className="cd-card wide100 heightAuto"
            src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
            alt={card.name}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        />
    );
};

export default SwipeableImage;
