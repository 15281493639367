import { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import ErrorPage from "../../display/ErrorPage"
import CardListDisplay from "../../display/CardListDisplay";


function CardTagDetails(props) {
    const {
        card_tags,
        cards
    } = props

    const {card_tag_id} = useParams()

    const [cardTag, setCardTag ] = useState({
        name: "",
        rules: "",
        tag_number: "",
        support: [],
        anti_support: [],
    });
    const [noCardTag, setNoCardTag] = useState(false)


    const [support_list, setSupportList] = useState([]);
    const [anti_support_list, setAntiSupportList] = useState([]);

    const [members, setMembers] = useState([]);

    const [showPool, setShowPool] = useState(true);
    const [showSupport, setShowSupport] = useState(true);
    const [showAntiSupport, setShowAntiSupport] = useState(true);

    const getCardTag = async() =>{
        const cardsResponse = await fetch("https://pm-deck-react-only.onrender.com/cards")
        const cardsData = await cardsResponse.json()

        const card_tag_data = card_tags.find(card_tag => card_tag.id === card_tag_id)
        if (card_tag_data) {
            setCardTag(card_tag_data);

            const sortedCards = [...cardsData].sort((a,b) => a.name.localeCompare(b.name));
            const tagMembersList = sortedCards.filter(card => card.card_tags.includes(card_tag_data.tag_number))
            setMembers(tagMembersList)

            const support_card_list = card_tag_data.support.map(supportItem =>
                cardsData.find(card => card.card_number === supportItem))
            const anti_support_card_list = card_tag_data.anti_support.map(antiSupportItem =>
                cardsData.find(card => card.card_number === antiSupportItem))
            setSupportList(support_card_list)
            setAntiSupportList(anti_support_card_list)
        } else {
            setNoCardTag(true)
        }
    };

    useEffect(() => {
        window.scroll(0, 0);
        getCardTag();
    // eslint-disable-next-line
    },[]);

    useEffect(() => {
        document.title = `${cardTag.name} - PM CardBase`
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[cardTag]);

    const handleShowPool = (event) => {
        setShowPool(!showPool);
    };

    const handleShowSupport = (event) => {
        setShowSupport(!showSupport);
    };

    const handleShowAntiSupport = (event) => {
        setShowAntiSupport(!showAntiSupport);
    };

    const preprocessText = (text) => {
        return text.split("//").join("\n");
    };

    return (
        <>
            { !noCardTag?
                <div className="white-space">
                    <h1 className="margin-top-40">{cardTag.name}</h1>
                    <h2>{cardTag.rules}</h2>
                        <div className={showPool ? "rarities" : "no-rarities"} style={{marginTop: "20px"}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <h2
                                    className="left"
                                    style={{margin: "1% 0px 1% 20px", fontWeight: "700"}}
                                >Members</h2>
                                <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                {members.length > 0 ?
                                    <h5
                                        className="left db-pool-count"
                                    >{members.length}</h5>:
                                    null}
                                { showPool ?
                                    <h5 className="left db-pool-count"
                                        onClick={() => handleShowPool()}>
                                            &nbsp;[Hide]
                                    </h5> :
                                    <h5 className="left db-pool-count"
                                        onClick={() => handleShowPool()}>
                                        &nbsp;[Show]
                                    </h5>}
                            </div>
                            <CardListDisplay
                                showPool={showPool}
                                members={members}
                            />
                        </div>
                        <div className={support_list.length > 0? "support":"hidden2"}>
                            <div>
                                <div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                    <h2
                                        className="left"
                                        style={{margin: "1% 0%", fontWeight: "700"}}
                                    >Support</h2>
                                    <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                    {support_list.length > 0 ?
                                    <h5
                                        className="left"
                                        style={{margin: "1% 0%", fontWeight: "700"}}
                                    >{support_list.length}</h5>:
                                    null}
                                    { showSupport ?
                                        <h5 className={support_list.length > 0 ? "left db-main-count" : "hidden2"}
                                            onClick={() => handleShowSupport()}>
                                                &nbsp;[Hide]
                                        </h5> :
                                        <h5 className={support_list.length > 0 ? "left db-main-count" : "hidden2"}
                                            onClick={() => handleShowSupport()}>
                                            &nbsp;[Show]
                                        </h5>}
                                </div>

                                {support_list.length > 0 ?
                                    <CardListDisplay
                                        showPool={showSupport}
                                        members={support_list}
                                    />:
                            <h4 className="left no-cards">No cards added</h4>}
                        </div>
                        </div>
                        <div className={anti_support_list.length > 0? "anti_support":"hidden2"}>
                            <div>
                                <div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                    <h2
                                        className="left"
                                        style={{margin: "1% 0%", fontWeight: "700"}}
                                    >Anti-Support</h2>
                                    <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                    {anti_support_list.length > 0 ?
                                    <h5
                                        className="left"
                                        style={{margin: "1% 0%", fontWeight: "700"}}
                                    >{anti_support_list.length}</h5>:
                                    null}
                                    { showAntiSupport ?
                                        <h5 className={anti_support_list.length > 0 ? "left db-main-count" : "hidden2"}
                                            onClick={handleShowAntiSupport}
                                        >
                                            &nbsp;[Hide]
                                        </h5> :
                                        <h5 className={anti_support_list.length > 0 ? "left db-main-count" : "hidden2"}
                                            onClick={handleShowAntiSupport}
                                        >
                                            &nbsp;[Show]
                                        </h5>}
                                </div>
                                {anti_support_list.length > 0 ?
                                    <CardListDisplay
                                        showPool={showAntiSupport}
                                        members={anti_support_list}
                                    />:
                            <h4 className="left no-cards">No cards added</h4>}
                        </div>
                        </div>
                </div>:
                <ErrorPage path={"/cardtags/"}/>
            }
        </>
    );
}

export default CardTagDetails;
