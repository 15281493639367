import { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import PopOver from "./PopOver";

function CardListDisplay({showPool, members, style}) {

    const [hoveredCard, setHoveredCard] = useState(null)

    return (
        <div>
            <PopOver
                content={hoveredCard}
                setObject={setHoveredCard}
            />
            <div className={showPool ? "card-pool-fill2" : "hidden2"} style={style? style: {}}>
                {members.map((card) => {
                    return (
                        <NavLink to={`/cards/${card.card_number}`} key={card.name}>
                            <div className="flex-content none">
                                <img
                                    className="builder-card2 pointer glow3"
                                    src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                    alt={card.name}/>
                            </div>
                            <div className="media-flex-center hidden2">
                                <img
                                    className="builder-card2 pointer glow3 trigger"
                                    src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                    onMouseEnter={() => setHoveredCard(card)}
                                    onMouseLeave={() => setHoveredCard(null)}
                                    alt={card.name}/>
                            </div>
                        </NavLink>
                    );
                })}
            </div>
        </div>
    )
}

export default CardListDisplay
