import { NavLink, useNavigate } from 'react-router-dom';


function Table({
    list,
    link,
    noEllipsis
}) {


    return (
        <div className="fullTableBorder">
            {list.map(function(item, index, arr) {
                return (
                    <>
                        { link?
                            <NavLink to={`/${link}/${item.id}`} className="nav-link no-pad flex" key={item.name}>
                                <div className="table200">
                                    <h5 className="text-table aligned">{item.name}</h5>
                                </div>
                                <div className="tableText">
                                    <h5 className={noEllipsis? "text-table-2": "text-table-2 ellipsis"}>
                                        {item.description||item.rules||item.text}
                                    </h5>
                                </div>
                            </NavLink>
                            :
                            <div className="nav-link no-pad flex" key={item.id}>
                                <div className="table200">
                                    <h5 className="text-table aligned">{item.name}</h5>
                                </div>
                                <div className="tableText">
                                    <h5 className="text-table-2">{item.description||item.rules||item.text}</h5>
                                </div>
                            </div>
                        }
                    </>
                );
            })}
        </div>
    );
}

export default Table;
