import {
    Card,
} from "react-bootstrap";
import { useState, useEffect, useRef, useContext } from "react";
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import BackButton from "../display/BackButton";
import PackOpener from "./PackOpener";
import { PullsContext } from "../context/PullsContext";
import PopOver from "../display/PopOver";


function PullPage() {

    const {card_set_id} = useParams();

    const [num, setNum] = useState("");
    const [boosterSet, setBoosterSet] = useState("")
    const [noBoosterSet, setNoBoosterSet] = useState(true)

    const [savedPulls, setSavedPulls] = useState([]);
    const {
        // boosterSetPulled,
        setBoosterSetPulled,
        pulls,
        setPulls,
        pullsList,
        pulling,
    } = useContext(PullsContext);

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [fullView, setFullView] = useState(false)

    const [maxVariables, setMaxVariables] = useState([])
    const [normals, setNormals] = useState([])
    const [rares, setRares] = useState([])
    const [superRares, setSuperRares] = useState([])
    const [ultraRares, setUltraRares] = useState([])

    const [perPack, setPerPack] = useState("")
    const [date_created, setDateCreated] = useState("")

    const getBoosterSet = async() => {
        const cardsResponse = await fetch("https://pm-deck-react-only.onrender.com/cards")
        const cardsData = await cardsResponse.json()

        const boosterSetResponse = await fetch(`https://pm-deck-react-only.onrender.com/booster_sets/${card_set_id}`)
        const boosterSetData = await boosterSetResponse.json()
        if (boosterSetData) {
            setBoosterSet(boosterSetData)
            const ratio = boosterSetData.ratio
            setPerPack(ratio.normals + ratio.rares + ratio.supers + ratio.mv)
            setDateCreated(boosterSetData.created_on.date_created)

            const maxVariablesList = []
            const normalsList = []
            const raresList = []
            const superRaresList = []
            const ultraRaresList = []

            for (let card_number of boosterSetData.mv) {
                maxVariablesList.push(cardsData.find(card => card.card_number === card_number))
            }
            setMaxVariables(maxVariablesList)
            for (let card_number of boosterSetData.normals) {
                normalsList.push(cardsData.find(card => card.card_number === card_number))
            }
            setNormals(normalsList)
            for (let card_number of boosterSetData.rares) {
                raresList.push(cardsData.find(card => card.card_number === card_number))
            }
            setRares(raresList)
            for (let card_number of boosterSetData.super_rares) {
                superRaresList.push(cardsData.find(card => card.card_number === card_number))
            }
            setSuperRares(superRaresList)
            for (let card_number of boosterSetData.ultra_rares) {
                ultraRaresList.push(cardsData.find(card => card.card_number === card_number))
            }
            setUltraRares(ultraRaresList)
            setNoBoosterSet(false)
        }
    }

    const getPulls = async() =>{
        if (!noBoosterSet) {
            setLoading(true)
            const newPulls = []
            if (pullsList.pulls) {
                for (let pull of pullsList.pulls) {
                    newPulls.push(pull.pulled_cards)
                }
                const allPulls = savedPulls.concat(newPulls)
                console.log(allPulls)

                setPulls(allPulls)

                if (lastSavedPullRef.current) {
                    lastSavedPullRef.current.scrollIntoView({ behavior: 'smooth' });
                }
                findUltras()
            }
        }
    }

    useEffect(() => {
        document.title = `Pack Openings - PM CardBase`
        return () => {
            document.title = "PlayMaker CardBase"
        };
    },[]);

    useEffect(() => {
        getBoosterSet()
        getPulls();
    },[pulling]);

    const handleFullView = (event) => {
        setFullView(!fullView);
    };

    const handleChangeNum = (event) => {
        setNum(event.target.value)
    };

    const lastSavedPullRef = useRef(null);

    const findUltras = (pull) => {
        if (pull && !noBoosterSet) {
            return pull.reduce(function(ultras, card, index, arr) {
                if (boosterSet.ultra_rares.includes(card.card_number)) {
                    ultras.push(card);
                }
                return ultras;
            }, []);
        } else {
            return []
        }
    };

    const handleSavePulls= (event) => {
        setSavedPulls(pulls)
        findUltras()
    }

    const handleClearPulls = (event) => {
        setBoosterSetPulled("")
        setPulls([])
        setSavedPulls([])
    }

    const getAllCards = (pulls) => {
        return pulls.reduce((all_cards, pull) => all_cards.concat(pull))
    }

    const [hoveredCard, setHoveredCard] = useState(null)

    return (
        <div className="white-space">
            <PopOver
                content={hoveredCard}
                setObject={setHoveredCard}
            />
            <Card className="text-white text-center card-list-card3" style={{margin: "2% 0%" }}>
                <div className="card-image-wrapper">
                    <div className="card-image-clip2">
                        <Card.Img
                            src={boosterSet.cover_image ? boosterSet.cover_image : "https://i.imgur.com/8wqd1sD.png"}
                            alt={boosterSet.name}
                            className="card-image2"
                            variant="bottom"/>
                    </div>
                </div>
                <Card.ImgOverlay className="blackfooter2 mt-auto">
                    <h3 className="left cd-container-child ellipsis2">{boosterSet.name}</h3>
                    <h6 className="left ellipsis2"
                        style={{margin: '0px 0px 10px 10px', fontWeight: "600"}}
                        >
                        Ultra Rares: {ultraRares.length} &nbsp; Super Rares: {superRares.length} &nbsp;
                        Rares: {rares.length} &nbsp; Normals: {normals.length} &nbsp; Max Variables: {maxVariables.length}
                    </h6>
                    <h6 className="left"
                        style={{margin: '0px 0px 10px 10px', fontWeight: "600"}}
                        >
                        {perPack} Cards Per Pack
                    </h6>
                    <div style={{ display: "flex" }}>
                        <img className="logo2" src="https://i.imgur.com/nIY2qSx.png" alt="created on"/>
                        <h6
                        className="left justify-content-end"
                        style={{margin: '5px 0px 5px 5px', fontWeight: "600", textAlign: "left"}}
                        >
                            {date_created}
                        </h6>
                    </div>
                </Card.ImgOverlay>
            </Card>

            {boosterSet.description ?
            <div className="margin-10">
                <h5 className="left-h1">{boosterSet.description}</h5>
            </div>:
            null}

            <div className="button-fill">
                <input
                    className="left dcbsearch-semi"
                    type="text"
                    placeholder=" Number of Packs"
                    onChange={handleChangeNum}
                    value={num}>
                </input>
                <PackOpener
                    boosterSet={boosterSet}
                    maxVariables={maxVariables}
                    normals={normals}
                    rares={rares}
                    superRares={superRares}
                    ultraRares={ultraRares}
                    num={num}
                />
                {fullView?
                    <button
                        className="left media-center"
                        onClick={handleFullView}
                    >
                        Multiple View
                    </button>:
                    <button
                        className="left media-center"
                        onClick={handleFullView}
                    >
                        Single View
                    </button>}
                <button onClick={handleSavePulls} className="left media-center">
                    Save Pulls
                </button>
                <button onClick={handleClearPulls} className="left media-center">
                    Clear Pulls
                </button>
                <button
                    className="left media-center"
                    onClick={() => navigate(`/pulls/deckbuilder`)}>
                    Create Deck
                </button>

                <BackButton/>
            </div>

            {!fullView && !noBoosterSet && pulls.length > 0?
                (pulls.map((pull, pullIndex) => {
                    return (
                        <div className="rarities" ref={pullIndex === savedPulls.length - 1 ? lastSavedPullRef : null}>
                            <div>
                                <div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                    <h2
                                        className="left"
                                        style={{margin: "1% 0%", fontWeight: "700"}}
                                    > Pull {pullIndex + 1} &nbsp; </h2>
                                { findUltras(pull).length > 0 ?
                                    <h2
                                        className="rainbow rainbow_text_animated"
                                        style={{margin: "1% 0%", fontWeight: "700"}}
                                    >
                                        { findUltras(pull).length > 1 ?
                                            ` ${findUltras(pull).length} Ultra Rares Detected!!`:
                                            ` 1 Ultra Rare Detected!!`
                                        }
                                    </h2>:
                                    null
                                }
                                </div>
                                <div className="card-pool-fill2">
                                    {pull.map((card) => {
                                        return (
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <NavLink to={`/cards/${card.card_number}`} key={card.name}>
                                                    {boosterSet.ultra_rares.includes(card.card_number) ?
                                                        <>
                                                            <div className="ultra none">
                                                                <img
                                                                    className="builder-card4"
                                                                    src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                                    alt={card.name}/>
                                                            </div>
                                                            <div className="ultra media-flex-center hidden2 trigger">
                                                                <img
                                                                    className="builder-card4"
                                                                    src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                                    alt={card.name}
                                                                    onMouseEnter={() => setHoveredCard(card)}
                                                                    onMouseLeave={() => setHoveredCard(null)}/>
                                                            </div>
                                                        </>:
                                                        <>
                                                            <div className="flex-content none">
                                                                <img
                                                                    className="builder-card2 pointer glow3"
                                                                    src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                                    alt={card.name}/>
                                                            </div>
                                                            <div className="media-flex-center hidden2">
                                                                <img
                                                                    className="builder-card2 pointer glow3 trigger"
                                                                    src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                                    onMouseEnter={() => setHoveredCard(card)}
                                                                    onMouseLeave={() => setHoveredCard(null)}
                                                                    alt={card.name}/>
                                                            </div>
                                                        </>
                                                    }
                                                </NavLink>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )})
                ):null
            }
            {fullView && !noBoosterSet && pulls.length > 0?
                <div className="rarities">
                    <div>
                        <div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                            <h2
                                className="left"
                                style={{margin: "1% 0%", fontWeight: "700"}}
                            >All Pulls &nbsp;</h2>
                            { findUltras(getAllCards(pulls)).length > 0 ?
                                <h2
                                    className="rainbow rainbow_text_animated"
                                    style={{margin: "1% 0%", fontWeight: "700"}}
                                >
                                    { findUltras(getAllCards(pulls)).length > 1 ?
                                        ` ${findUltras(getAllCards(pulls)).length} Ultra Rares Detected!!`:
                                        ` 1 Ultra Rare Detected!!`
                                    }
                                </h2>:
                                null
                            }
                        </div>
                        <div className="card-pool-fill2">
                            {getAllCards(pulls).sort((a,b) => a.card_number - b.card_number).map((card) => {
                                return (
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <NavLink to={`/cards/${card.card_number}`} key={card.name}>
                                            {boosterSet.ultra_rares.includes(card.card_number) ?
                                                <>
                                                    <div className="ultra none">
                                                        <img
                                                            className="builder-card4"
                                                            src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                            alt={card.name}/>
                                                    </div>
                                                    <div className="ultra media-flex-center hidden2 trigger">
                                                        <img
                                                            className="builder-card4"
                                                            src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                            alt={card.name}
                                                            onMouseEnter={() => setHoveredCard(card)}
                                                            onMouseLeave={() => setHoveredCard(null)}/>
                                                    </div>
                                                </>:
                                                <>
                                                    <div className="flex-content none">
                                                        <img
                                                            className="builder-card2 pointer glow3"
                                                            src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                            alt={card.name}/>
                                                    </div>
                                                    <div className="media-flex-center hidden2">
                                                        <img
                                                            className="builder-card2 pointer glow3 trigger"
                                                            src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                            onMouseEnter={() => setHoveredCard(card)}
                                                            onMouseLeave={() => setHoveredCard(null)}
                                                            alt={card.name}/>
                                                    </div>
                                                </>
                                            }
                                        </NavLink>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>:null
            }
        </div>
    )
}


export default PullPage;
