import React, { useRef, useEffect } from "react";
import "./DragToScroll.css"; // Add relevant styles if needed

const DragToScroll = ({ children, style }) => {
  const scrollRef = useRef(null); // Reference to the container

  // Add event listeners
    useEffect(() => {
        const buttonRow = scrollRef.current;

        if (!buttonRow) return;

        const handleWheelScroll = (e) => {
            e.preventDefault(); // Prevent vertical scroll
            buttonRow.scrollLeft += e.deltaY; // Scroll horizontally based on vertical wheel movement
        };

        // Attach event listener
        buttonRow.addEventListener("wheel", handleWheelScroll);

        // Cleanup on unmount
        return () => {
            buttonRow.removeEventListener("wheel", handleWheelScroll);
        };
    }, []);

    return (
        <div ref={scrollRef} className={`drag-scroll drag`} style={style}>
        <div className="drag">
        {children}
        </div>
        </div>
    );
};

export default DragToScroll;
