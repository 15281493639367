import { useLocation } from "react-router-dom";

function Footer() {
    const location = useLocation();
    // const openInNewTab = (url) => {
    //     window.open(url, "_blank", "noreferrer");
    // };
    const current_year = new Date().getFullYear()

    return (
        <div className={location.pathname.slice(0, 10) === "/simulator"? "hidden2": "footer"}>
            <p className="footer-copy-right">&copy;{current_year} JotH: PlayMaker &nbsp;</p>
            <a href={"https://discord.gg/hVfTNEZG9p"} target="_blank" rel="noreferrer">
                <img
                    className="social-icon pointer"
                    src="https://i.imgur.com/TVbM9Jg.png"
                    alt="discord">
                </img>
            </a>
            <a href={"https://www.instagram.com/jothplaymaker/"} target="_blank" rel="noreferrer">
                <img
                    className="social-icon pointer"
                    src="https://i.imgur.com/WstHvlw.png"
                    alt="instagram">
                </img>
            </a>
            <a href={"hhttps://www.twitch.tv/playmakersccg"} target="_blank" rel="noreferrer">
                <img
                    className="social-icon pointer"
                    src="https://i.imgur.com/QJUUtwL.png"
                    alt="twitch">
                </img>
            </a>
            <a href={"https://www.youtube.com/channel/UCGyH2iJdgKFnm2vEdoZ88Og?ref=jothplaymaker.com"} target="_blank" rel="noreferrer">
                <img
                    className="social-icon pointer"
                    src="https://i.imgur.com/Qk0npzS.png"
                    alt="youtube">
                </img>
            </a>
        </div>
    );
}

export default Footer;
