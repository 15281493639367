import TopRow from "./display/TopRow";
import DeckRow from "./display/DeckRow";
import CardRow from "./display/CardRow";
import NewsRow from "./display/NewsRow";
import SimulatorRow from "./display/SimulatorRow";
import AutoWidthText from "./display/AutoWidthText";


function MainPage() {

  // window.scroll(0, 0);
  document.body.style.overflow = 'auto';

    return (
      <div className="mainPage margin-auto">
        <h1 className="media-title">Welcome to PlayMaker CardBase</h1>
        <h2 className="media-title">The PlayMaker Card Database and Deck Sharing Site</h2>
        <br/>
        <TopRow/>
        <div className="margin-top-20 none">
          <SimulatorRow/>
        </div>
        <br/>
        <span className="none">
          <a
            href={"https://steamcommunity.com/sharedfiles/filedetails/?id=2959625751"}
            className="sim-row-link ttsBanner white-space none glow3"
            target="_blank"
            rel="noreferrer"
          >
            <div className="ttsLogoContainer">
              <img
                className="ttsLogo"
                src="ttslogo.png"
                alt="tts-logo"
              />
            </div>
            <AutoWidthText
              as="h1"
              text="Check Out The TTS Workshop On Steam!"
              style={{}}
              className="ttsText"
            />
            {/* <h1 className="ttsText">Check Out The TTS Workshop On Steam!</h1> */}
          </a>
        <br/>
        </span>
        <a
          href={"https://discord.gg/hVfTNEZG9p"}
          className="sim-row-link discordBanner white-space glow3"
          target="_blank"
          rel="noreferrer"
        >
          <div className="ttsLogoContainer">
            <img
              className="ttsLogo"
              src="discordLogo2.png"
              alt="tts-logo"
            />
          </div>
          <AutoWidthText
            as="h1"
            text="Join Us On Discord!"
            style={{}}
            className="ttsText"
          />
          {/* <h1 className="ttsText">Join Us On Discord!</h1> */}
        </a>
        <br/>
        <h1 className="margin-top-20">CardBase News</h1>
        <br/>
        <NewsRow/>
        <br/>
        <h1 className="margin-top-20 media-margin-bottom-none">Latest Cards</h1>
        <br/>
        <CardRow/>
        <br/>
        <h1 className="margin-top-20">Latest Decks</h1>
        <br/>
        <DeckRow/>
      </div>
    );
  }

  export default MainPage;
