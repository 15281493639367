import {
    Card,
} from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import { NavLink, useParams} from 'react-router-dom';
import BackButton from "../display/BackButton";
import ErrorPage from "../display/ErrorPage";
import { AuthContext } from "../context/AuthContext";
import CardListDisplay from "../display/CardListDisplay";
import PopOver from "../display/PopOver";


function SetDetailPage() {
    const {card_set_id} = useParams();
    const {account} = useContext(AuthContext)

    const [listView, setListView] = useState(false);
    const [showMaxVariables, setShowMaxVariables] = useState(false);
    const [showNormals, setShowNormals] = useState(false);
    const [showRares, setShowRares] = useState(false);
    const [showSuperRares, setShowSuperRares] = useState(false);
    const [showUltraRares, setShowUltraRares] = useState(false);

    const [boosterSet, setBoosterSet] = useState("")
    const [noBoosterSet, setNoBoosterSet] = useState(false)

    const [maxVariables, setMaxVariables] = useState([])
    const [normals, setNormals] = useState([])
    const [rares, setRares] = useState([])
    const [superRares, setSuperRares] = useState([])
    const [ultraRares, setUltraRares] = useState([])

    const [perPack, setPerPack] = useState("")
    const [date_created, setDateCreated] = useState("")

    const getBoosterSet = async() => {
        const cardsResponse = await fetch("https://pm-deck-react-only.onrender.com/cards")
        const cardsData = await cardsResponse.json()

        const boosterSetResponse = await fetch(`https://pm-deck-react-only.onrender.com/booster_sets/${card_set_id}`)
        const boosterSetData = await boosterSetResponse.json()
        if (boosterSetData) {
            setBoosterSet(boosterSetData)
            const ratio = boosterSetData.ratio
            setPerPack(ratio.normals + ratio.rares + ratio.supers + ratio.mv)
            setDateCreated(boosterSetData.created_on.date_created)

            const maxVariablesList = []
            const normalsList = []
            const raresList = []
            const superRaresList = []
            const ultraRaresList = []

            for (let card_number of boosterSetData.mv) {
                maxVariablesList.push(cardsData.find(card => card.card_number === card_number))
            }
            setMaxVariables(maxVariablesList)
            for (let card_number of boosterSetData.normals) {
                normalsList.push(cardsData.find(card => card.card_number === card_number))
            }
            setNormals(normalsList)
            for (let card_number of boosterSetData.rares) {
                raresList.push(cardsData.find(card => card.card_number === card_number))
            }
            setRares(raresList)
            for (let card_number of boosterSetData.super_rares) {
                superRaresList.push(cardsData.find(card => card.card_number === card_number))
            }
            setSuperRares(superRaresList)
            for (let card_number of boosterSetData.ultra_rares) {
                ultraRaresList.push(cardsData.find(card => card.card_number === card_number))
            }
            setUltraRares(ultraRaresList)
        } else {
            setNoBoosterSet(true)
        }
    }

    useEffect(() => {
        window.scroll(0, 0);
        getBoosterSet()
        document.title = `Card Sets - PM CardBase`
        return () => {
            document.title = "PlayMaker CardBase"
        };
    },[]);

    const handleListView = (event) => {
        setListView(!listView);
    };

    const handleShowMaxVariables = (event) => {
        setShowMaxVariables(!showMaxVariables);
    };

    const handleShowNormals = (event) => {
        setShowNormals(!showNormals);
    };

    const handleShowRares = (event) => {
        setShowRares(!showRares);
    };

    const handleShowSuperRares = (event) => {
        setShowSuperRares(!showSuperRares);
    };

    const handleShowUltraRares = (event) => {
        setShowUltraRares(!showUltraRares);
    };

    const [hoveredCard, setHoveredCard] = useState(null)


    return (
        <>
            <PopOver
                content={hoveredCard}
                setObject={setHoveredCard}
            />
            { !noBoosterSet?
                <div className="white-space">
                    <Card className="text-white text-center card-list-card3" style={{margin: "2% 0%" }}>
                        <div className="card-image-wrapper">
                            <div className="card-image-clip2">
                                <Card.Img
                                    src={boosterSet.cover_image ? boosterSet.cover_image : "https://i.imgur.com/8wqd1sD.png"}
                                    alt={boosterSet.name}
                                    className="card-image2"
                                    variant="bottom"/>
                            </div>
                        </div>
                        <Card.ImgOverlay className="blackfooter2 mt-auto">
                                <h3 className="left cd-container-child ellipsis2">{boosterSet.name}</h3>
                                <h6 className="left ellipsis2"
                                    style={{margin: '0px 0px 10px 10px', fontWeight: "600"}}
                                >
                                    Ultra Rares: {ultraRares.length} &nbsp; Super Rares: {superRares.length} &nbsp;
                                    Rares: {rares.length} &nbsp; Normals: {normals.length} &nbsp; Max Variables: {maxVariables.length}
                                </h6>
                                <h6 className="left"
                                    style={{margin: '0px 0px 10px 10px', fontWeight: "600"}}
                                >
                                    {perPack} Cards Per Pack
                                </h6>
                                <div style={{ display: "flex" }}>
                                    <img className="logo2" src="https://i.imgur.com/nIY2qSx.png" alt="created on"/>
                                    <h6
                                    className="left justify-content-end"
                                        style={{margin: '5px 0px 5px 5px', fontWeight: "600", textAlign: "left"}}
                                    >
                                        {date_created}
                                    </h6>
                                </div>
                        </Card.ImgOverlay>
                    </Card>

                    {boosterSet.description ?
                        <div className="margin-10">
                            <h5 className="left-h1">{boosterSet.description}</h5>
                        </div>: null
                    }

                    <div style={{ display: "flex" }}>
                        <NavLink to={`/cardsets/${boosterSet.id}/pulls`}>
                            <button
                                    className="left heightNorm"
                                    variant="danger"
                                    style={{marginLeft: ".5%", marginRight: "7px", width:"120px"}}
                                    >
                                    Open Packs
                            </button>
                        </NavLink>
                        {account && account.roles.includes("admin")?
                            <>
                                <NavLink to={`/cardsets/${boosterSet.id}/edit`}>
                                    <button
                                            className="left heightNorm red"
                                            style={{marginLeft: ".5%", marginRight: "7px"}}
                                            >
                                            Edit Set
                                    </button>
                                </NavLink>
                                <NavLink to={`/cardsets/${boosterSet.id}/copy`}>
                                    <button
                                            className="left heightNorm red"
                                            variant="danger"
                                            style={{marginLeft: ".5%", marginRight: "7px"}}
                                            >
                                            Copy Set
                                    </button>
                                </NavLink>
                            </>: null}
                    </div>

                    <div className="rarities">
                        <div>
                            <div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                <h2
                                    className="left"
                                    style={{margin: "1% 0%", fontWeight: "700"}}
                                >Ultra Rares</h2>
                                <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                {ultraRares.length > 0 ?
                                <h5
                                    className="left db-main-count"
                                >{ultraRares.length}</h5>:
                                null}
                                { showUltraRares ?
                                    <h5 className={ultraRares.length > 0 ? "left db-main-count" : "hidden2"}
                                        onClick={() => handleShowUltraRares()}>
                                            &nbsp;[Hide]
                                    </h5> :
                                    <h5 className={ultraRares.length > 0 ? "left db-main-count" : "hidden2"}
                                        onClick={() => handleShowUltraRares()}>
                                        &nbsp;[Show]
                                    </h5>}
                            </div>
                            {ultraRares.length > 0 ?
                                <div className={showUltraRares ? "card-pool-fill2" : "hidden2"} style={{marginBottom: "18px"}}>
                                    {ultraRares.sort((a,b) => a.card_number - b.card_number).map((card) => {
                                        return (
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <NavLink to={`/cards/${card.card_number}`} key={card.name}>
                                                    <div className="ultra none">
                                                        <img
                                                            className="builder-card4"
                                                            src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                            alt={card.name}
                                                            variant="bottom"/>
                                                    </div>
                                                    <div className="ultra media-flex-center hidden2 trigger">
                                                        <img
                                                            className="builder-card4"
                                                            src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                            alt={card.name}
                                                            onMouseEnter={() => setHoveredCard(card)}
                                                            onMouseLeave={() => setHoveredCard(null)}
                                                            variant="bottom"/>
                                                    </div>
                                                </NavLink>
                                            </div>
                                        );
                                    })}
                                </div>:
                            <h4 className="left no-cards">No cards added</h4>}
                        </div>
                    </div>

                    <div className="rarities">
                        <div>
                            <div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                <h2
                                    className="left"
                                    style={{margin: "1% 0%", fontWeight: "700"}}
                                >Super Rares</h2>
                                <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                {superRares.length > 0 ?
                                <h5
                                    className="left db-main-count"
                                >{superRares.length}</h5>:
                                null}
                                { showSuperRares ?
                                    <h5 className={superRares.length > 0 ? "left db-main-count" : "hidden2"}
                                        onClick={() => handleShowSuperRares()}>
                                            &nbsp;[Hide]
                                    </h5> :
                                    <h5 className={superRares.length > 0 ? "left db-main-count" : "hidden2"}
                                        onClick={() => handleShowSuperRares()}>
                                        &nbsp;[Show]
                                    </h5>}
                            </div>
                            {superRares.length > 0 ?
                                <CardListDisplay
                                    showPool={showSuperRares}
                                    members={superRares}
                                    style={{marginBottom: "18px"}}
                                />: <h4 className="left no-cards">No cards added</h4>}
                        </div>
                    </div>

                    <div className="rarities">
                        <div>
                            <div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                <h2
                                    className="left"
                                    style={{margin: "1% 0%", fontWeight: "700"}}
                                >Rares</h2>
                                <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                {rares.length > 0 ?
                                <h5
                                    className="left db-main-count"
                                >{rares.length}</h5>:
                                null}
                                { showRares ?
                                    <h5 className={rares.length > 0 ? "left db-main-count" : "hidden2"}
                                        onClick={() => handleShowRares()}>
                                            &nbsp;[Hide]
                                    </h5> :
                                    <h5 className={rares.length > 0 ? "left db-main-count" : "hidden2"}
                                        onClick={() => handleShowRares()}>
                                        &nbsp;[Show]
                                    </h5>}
                            </div>
                            {rares.length > 0 ?
                                <CardListDisplay
                                    showPool={showRares}
                                    members={rares}
                                    style={{marginBottom: "18px"}}
                                />: <h4 className="left no-cards">No cards added</h4>}
                        </div>
                    </div>

                    <div className="rarities">
                        <div>
                            <div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                <h2
                                    className="left"
                                    style={{margin: "1% 0%", fontWeight: "700"}}
                                >Normals</h2>
                                <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                {normals.length > 0 ?
                                <h5
                                    className="left db-main-count"
                                >{normals.length}</h5>:
                                null}
                                { showNormals ?
                                    <h5 className={normals.length > 0 ? "left db-main-count" : "hidden2"}
                                        onClick={() => handleShowNormals()}>
                                            &nbsp;[Hide]
                                    </h5> :
                                    <h5 className={normals.length > 0 ? "left db-main-count" : "hidden2"}
                                        onClick={() => handleShowNormals()}>
                                        &nbsp;[Show]
                                    </h5>}
                            </div>
                            {normals.length > 0 ?
                                <CardListDisplay
                                    showPool={showNormals}
                                    members={normals}
                                    style={{marginBottom: "18px"}}
                                />: <h4 className="left no-cards">No cards added</h4>}
                        </div>
                    </div>

                    <div className="rarities">
                        <div>
                            <div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                                <h2
                                    className="left"
                                    style={{margin: "1% 0%", fontWeight: "700"}}
                                >Max Variables</h2>
                                <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                                {maxVariables.length > 0 ?
                                <h5
                                    className="left db-main-count"
                                >{maxVariables.length}</h5>:
                                null}
                                { showMaxVariables ?
                                    <h5 className={maxVariables.length > 0 ? "left db-main-count" : "hidden2"}
                                        onClick={() => handleShowMaxVariables()}>
                                            &nbsp;[Hide]
                                    </h5> :
                                    <h5 className={maxVariables.length > 0 ? "left db-main-count" : "hidden2"}
                                        onClick={() => handleShowMaxVariables()}>
                                        &nbsp;[Show]
                                    </h5>}
                            </div>
                            {maxVariables.length > 0 ?
                                <CardListDisplay
                                    showPool={showMaxVariables}
                                    members={maxVariables}
                                    style={{marginBottom: "18px"}}
                                />: <h4 className="left no-cards">No cards added</h4>}
                        </div>
                    </div>
                </div>:
                <ErrorPage path={"/cardsets/"}/>
            }
        </>
    );
}


export default SetDetailPage;
